<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="105px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商名称：" prop="title">
              {{formData.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商类型：">
              <span v-for="item in formData.type" :key="item">
                 {{item | supplierType2Text}}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="供应商地址：">
              {{formData.location}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商风格：">
              {{formData.style}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="供应商标签">
              {{formData.tag}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供应商描述" prop="orderNo">
              {{formData.description}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="供应商封面">
              <img class="img" @click="showImg(formData.cover, '供应商封面')" :src="formData.cover" alt="">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-button @click="backToList">返回列表</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { execute } from '../productManage/option'
export default {
  name: "supplierDetail",
  data() {
    return {
      formData: {},
      id: ''
    }
  },
  methods: {
    ...mapActions(['getSupplierById']),
    tryGetSupplierById() {
      this.getSupplierById(this.id).then(res => {
        console.log(res)
        this.formData = res
      })
    },
    // 展示图片
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    backToList() {
      this.Event.$emit('closeTab')
      this.$router.push({
        name: 'supplierList'
      })
    }
  },
  created() {
    this.id = this.$route.params.supplierId
    this.tryGetSupplierById()
  }
}
</script>

<style scoped>

</style>